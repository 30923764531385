import React from 'react';

const Share2 = (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4087 2.09083C13.7643 1.92797 14.1822 1.98737 14.4783 2.24287L24.2988 10.7157C24.5112 10.899 24.6369 11.1632 24.6451 11.4437C24.6533 11.7242 24.5432 11.9952 24.3418 12.1906L14.5214 21.7178C14.233 21.9975 13.8053 22.0775 13.4354 21.921C13.0654 21.7644 12.8251 21.4017 12.8251 21V16.0701C6.5982 16.3461 3.38763 19.1819 2.58292 21.3482C2.41437 21.802 1.94502 22.0699 1.46861 21.9842C0.992196 21.8986 0.645508 21.4841 0.645508 21C0.645508 15.2325 2.57785 11.8634 5.36208 10.0016C7.71198 8.43021 10.519 8.04171 12.8251 7.96713V3.00001C12.8251 2.6089 13.0531 2.25368 13.4087 2.09083ZM14.8251 5.18354V8.95183C14.8251 9.50411 14.3773 9.95183 13.8251 9.95183C11.4331 9.95183 8.64991 10.209 6.47382 11.6641C4.9018 12.7153 3.53248 14.4737 2.9475 17.5193C5.18572 15.532 8.84698 14.0482 13.8251 14.0482C14.3773 14.0482 14.8251 14.4959 14.8251 15.0482V18.6366L22.1648 11.5161L14.8251 5.18354Z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.645508)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Share2;
