import React from 'react';
import OutboundLink from '../OutboundLink';
import { TextInput, Checkbox } from '../Form';

const schema = {
  email: {
    label: 'Email',
    component: TextInput,
    type: 'email',
    value: '',
    placeholder: 'email@address.com',
    required: true,
    validation: {
      test: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      message: 'Please provide a valid email address.',
    },
  },
  inGradesKto3: {
    label: 'Grades K–3',
    component: Checkbox,
    checked: false,
  },
  inGrades4to5: {
    label: 'Grades 4–5',
    component: Checkbox,
    checked: false,
  },
  inGrades6to8: {
    label: 'Grades 6–8',
    component: Checkbox,
    checked: false,
  },
  inGrades9to12: {
    label: 'Grades 9–12',
    component: Checkbox,
    checked: false,
  },
  interestedInSTEM: {
    label: 'STEM',
    component: Checkbox,
    checked: false,
  },
  interestedInLanguageArts: {
    label: 'Language Arts',
    component: Checkbox,
    checked: false,
  },
  interestedInEducation: {
    label: 'Health and Physical Education',
    component: Checkbox,
    checked: false,
  },
  interestedInVirtualFieldTrips: {
    label: 'Virtual Field Trips',
    component: Checkbox,
    checked: false,
  },
  interestedInSweepstakesAndChallenges: {
    label: 'Sweepstakes and Challenges',
    component: Checkbox,
    checked: false,
  },
  subscribeToDE: {
    label: (
      <>
        Stay up-to-date with service enhancements, new content, professional
        development opportunities, special events, education program and news
        from Discovery Education in accordance with the following{' '}
        <a
          href="https://www.discoveryeducation.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </>
    ),
    component: Checkbox,
    checked: false,
  },
  subscribeToPartner: {
    label: (programName, partnerPrivacyUrl) => (
      <>
        I would like to receive information about the other STEM education
        topics from {programName} in accordance with the following{' '}
        <OutboundLink to="https://www.discoveryeducation.com/privacy-policy">Privacy Policy</OutboundLink>.
      </>
    ),
    component: Checkbox,
    checked: false,
  },
};

export default schema;
