import React from 'react';

const CircleCheck = (props) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24.5C18.6274 24.5 24 19.1274 24 12.5C24 5.87258 18.6274 0.5 12 0.5C5.37258 0.5 1.68587e-07 5.87258 0 12.5C5.05762e-07 19.1274 5.37258 24.5 12 24.5ZM17.7071 10.2071C18.0976 9.8166 18.0976 9.18344 17.7071 8.79291C17.3166 8.40239 16.6834 8.40239 16.2929 8.79291L10 15.0858L7.70711 12.7929C7.31658 12.4024 6.68342 12.4024 6.29289 12.7929C5.90237 13.1834 5.90237 13.8166 6.29289 14.2071L9.29289 17.2071C9.48043 17.3947 9.73478 17.5 10 17.5C10.2652 17.5 10.5196 17.3947 10.7071 17.2071L17.7071 10.2071Z"
      fill="#28393E"
    />
  </svg>
);

export default CircleCheck;
