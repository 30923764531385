import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';
import Icon from '../Icon';
import Button from '../Button';
import OutboundLink from '../OutboundLink';
import './styles.scss';

const Share = ({ inDropDown, className, ...other }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            tweetText
            emailSubject
            emailBody
          }
        }
      }
    `
  );

  const shareLinks = {
    Facebook: `https://www.facebook.com/sharer/sharer.php?u=${site.siteMetadata.siteUrl}`,
    Twitter: `https://twitter.com/intent/tweet?text=${site.siteMetadata.tweetText}`,
    Email: `mailto:?subject=${site.siteMetadata.emailSubject}&body=${site.siteMetadata.emailBody}`,
  };

  const [isVisible, setIsVisible] = useState(false);
  const [selected, setSelected] = useState(null);

  const button = useRef(null);
  const list = useRef(null);

  useEffect(() => {
    if (selected) {
      list.current.querySelector(`#${selected}`).focus();
    }
  }, [selected]);

  const handleButtonClick = () => {
    setIsVisible(!isVisible);
  };

  const handleButtonKeyDown = (e) => {
    if (e.keyCode === 13 || e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();
      if (!isVisible) {
        setIsVisible(true);
        setSelected(list.current.children[0].id);
      } else if (e.keyCode === 13) {
        setIsVisible(false);
        setSelected(null);
      }
    }
  };

  const handleListKeyDown = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();

      const currentlySelected = list.current.querySelector(`#${selected}`);
      const nextSibling = currentlySelected.nextElementSibling;
      const prevSibling = currentlySelected.previousElementSibling;

      if (e.keyCode === 40 && nextSibling) {
        setSelected(nextSibling.id);
      } else if (e.keyCode === 38 && prevSibling) {
        setSelected(prevSibling.id);
      }
    }

    if (
      (e.keyCode === 13 || e.keyCode === 27 || e.keyCode === 9) &&
      isVisible
    ) {
      setIsVisible(false);
      setSelected(null);
      button.current.focus();
    }
  };

  return (
    <div
      className={classNames(
        'share',
        inDropDown ? 'share--dropdown' : 'share--inline',
        className
      )}
      {...other}
    >
      {inDropDown ? (
        <Button
          link
          className="share__button"
          onClick={handleButtonClick}
          onKeyDown={handleButtonKeyDown}
          id="share__button"
          aria-haspopup="listbox"
          aria-expanded={isVisible}
          ref={button}
        >
          Share <Icon name="share2" marginLeft />
        </Button>
      ) : (
        <span className="share__inline-label text-sans-serif">Share</span>
      )}
      <div
        className={classNames(
          'share__links',
          inDropDown && 'share__links--stacked',
          inDropDown && isVisible && 'share__links--visible'
        )}
        role={inDropDown ? 'listbox' : null}
        tabIndex={inDropDown ? '-1' : null}
        aria-labelledby={inDropDown ? 'share__button' : null}
        aria-activedescendant={inDropDown ? selected : null}
        onKeyDown={inDropDown ? handleListKeyDown : null}
        ref={list}
      >
        {Object.keys(shareLinks).map((key, i) => (
          <OutboundLink
            key={i}
            to={shareLinks[key]}
            className="share__link"
            tabIndex={inDropDown ? '-1' : null}
            id={key.toLowerCase()}
            role={inDropDown ? 'option' : null}
            aria-selected={inDropDown && selected === key.toLowerCase()}
          >
            <Icon name={key.toLowerCase()} className="share__link-icon" />
            <span
              className={classNames(
                'share__link-label',
                !inDropDown && 'share__link-label--sr-only'
              )}
            >
              {key}
            </span>
          </OutboundLink>
        ))}
      </div>
    </div>
  );
};

Share.propTypes = {
  /** Display the share links in a drop-down list */
  inDropDown: PropTypes.bool,
  /** A custom class name */
  className: PropTypes.string,
};

export default Share;
