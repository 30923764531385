import React from 'react';

const Updates = (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="2 1 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.64551 5C2.64551 3.34315 3.98865 2 5.64551 2H18.6455C20.3024 2 21.6455 3.34315 21.6455 5V9.09091C21.6455 9.64319 21.1978 10.0909 20.6455 10.0909C20.0932 10.0909 19.6455 9.64319 19.6455 9.09091V5C19.6455 4.44772 19.1978 4 18.6455 4H5.64551C5.09322 4 4.64551 4.44772 4.64551 5V15C4.64551 15.5523 5.09322 16 5.64551 16H6.64551C7.19779 16 7.64551 16.4477 7.64551 17C7.64551 17.5523 7.19779 18 6.64551 18H5.64551C3.98865 18 2.64551 16.6569 2.64551 15V5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.3526 16.2929C22.7431 16.6834 22.7431 17.3166 22.3526 17.7071L18.3526 21.7071C17.9621 22.0976 17.3289 22.0976 16.9384 21.7071C16.5479 21.3166 16.5479 20.6834 16.9384 20.2929L19.2313 18H11.6455C11.0932 18 10.6455 17.5523 10.6455 17C10.6455 16.4477 11.0932 16 11.6455 16L19.2313 16L16.9384 13.7071C16.5479 13.3166 16.5479 12.6834 16.9384 12.2929C17.3289 11.9024 17.9621 11.9024 18.3526 12.2929L22.3526 16.2929Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.77655 2H18.5145C20.2437 2 21.6455 3.40182 21.6455 5.13104C21.6455 6.54957 20.6918 7.79087 19.3212 8.15637L13.1762 9.79505C12.5009 9.97513 11.7902 9.97513 11.1149 9.79505L4.9698 8.15637C3.59917 7.79087 2.64551 6.54957 2.64551 5.13104C2.64551 3.40182 4.04732 2 5.77655 2ZM5.77655 4C5.15189 4 4.64551 4.50639 4.64551 5.13104C4.64551 5.64346 4.99001 6.09187 5.48512 6.2239L11.6302 7.86258C11.9678 7.95262 12.3232 7.95262 12.6608 7.86258L18.8059 6.2239C19.301 6.09187 19.6455 5.64346 19.6455 5.13104C19.6455 4.50639 19.1391 4 18.5145 4H5.77655Z"
    />
  </svg>
);

export default Updates;
