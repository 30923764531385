import React from 'react';

const PptxDownload = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 62 26"
    style={{
      enableBackground: 'new 0 0 62 26',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M17.1 14.9v5h-14v-5h-2v5c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-5h-2z" />
    <path d="M15.1 11.9h-4v-8h-2v8h-4l5 6z" />
    <text
      transform="translate(21.412 19.334)"
      style={{
        fontSize: 14,
        fontFamily: 'Open Sans',
        fontWeight: 700,
      }}
    >
      {'.PPTX'}
    </text>
  </svg>
);

export default PptxDownload;
