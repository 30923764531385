import React from 'react';

const GoogleSlidesDownload = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 62 26"
    style={{
      enableBackground: 'new 0 0 62 26',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {".st0{font-family:'Open Sans'}.st1{font-size:12px}.st2{font-weight:700}"}
    </style>
    <path d="M15.9 15.4v5h-14v-5h-2v5c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-5h-2z" />
    <path d="m8.9 18.4 5-6h-4v-8h-2v8h-4l5 6z" />
    <text transform="translate(21.393 9.802)" className="st0 st1 st2">
      {'Google'}
    </text>
    <text transform="translate(21.393 24.202)" className="st0 st1 st2">
      {'slides'}
    </text>
  </svg>
);

export default GoogleSlidesDownload;
